main.gradient {
  color: #fff;
  background: linear-gradient(-45deg, #EE7752, #d14279, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 120s ease infinite;
  -moz-animation: Gradient 120s ease infinite;
  animation: Gradient 120s ease infinite;
}

main.paused {
  -webkit-animation-play-state: paused;
  /* Safari 4.0 - 8.0 */
  animation-play-state: paused;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

main.dark {
  color: #fff;
  background-color: #333;
}

main.light {
  color: #555;
  background-color: #fafafa;
}
